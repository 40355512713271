<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="7"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示:</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

        </b-row>

        <b-card>
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- Search 仓库、商品名称、商品69码 -->
              <b-col
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="productName"
                      size="sm"
                      v-model="productName"
                      name="productName"
                      @click="showSingleModal('商品')"
                      readonly
                      placeholder="搜索商品名称"
                  />

                </b-form-group>
              </b-col>

<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="仓库:"-->
<!--                    label-for="contact"-->
<!--                    label-size="sm"-->
<!--                    class="mb-1"-->
<!--                >-->
<!--                  <v-select-->
<!--                      :options="warehouseArray"-->
<!--                      v-model="soWarehouseId"-->
<!--                      placeholder="点击选择仓库"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="库存状态:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('stock_status')"
                      v-model="status"
                      placeholder="点击选择库存状态"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否扣除待出库库存:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="enable_stock_inventory"
                      v-model="isReduceStock"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="优先使用vmi库存:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="vmi"
                      v-model="vmi"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="排序规则:"
                    label-for="contact"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="orderByArray"
                      v-model="orderByOption"
                      placeholder="点击选择排序规则"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="使用数量:"
                    label-for="qty"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      v-model="qty"
                      placeholder="请输入使用数量"
                      type="number"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <xy-input-button label="日期"
                                 type="onlyMonth"
                                 v-on:change="fromChildren($event)"
                                 :params="['month']"
                                 :value="month"
                                 class="mr-2"
                >
                </xy-input-button>
              </b-col>



              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchALlList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="alldata"
      >

        <!-- Columns -->
        <template #cell(stock_id)="data">
          <b-form-checkbox
              v-model="data.item.isSelect"
              value="1"
              unchecked-value="0"
              @change="changeSelect('isSelect',data.item.isSelect,data.index)"
          >
            #{{ data.item.stock_id }}
          </b-form-checkbox>
        </template>

        <template #cell(company_id)="data">
          {{ getCodeLabel("company", data.item.company_id) }}
        </template>

        <template #cell(warehouse_id)="data">
          {{ getCodeLabel("warehouse", data.item.warehouse_id) }}
        </template>

        <template #cell(production_date)="data">
          {{toMonth(data.item.production_date)}}
        </template>

        <template #cell(code)="data">
          {{ data.item.ext.productcode}}
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}{{ data.item.is_gift === 1 ? "(赠)" : "" }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '库存编号：'+data.item.stock_no+
              '<br>'+'产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(status)="data">
          <!--          <span :class="'text-'+getCode('stock_status', data.item.status).color">{{ getCodeLabel("stock_status", data.item.status)}}</span>-->
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
          >
            {{ getCodeLabel("stock_status", data.item.status) }}
          </b-badge>
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(outboundTrueQty)="data">
          {{data.item.ext.outboundTrueQty}}
        </template>

        <template #cell(useQty)="data">
          <b-form-input
              v-model="data.item.ext.useQty"
              @change="changeQty($event,data.item,data.index)"
              type="number"
          />
        </template>

        <template #cell(is_gift)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_gift==0"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <!-- Column: State -->
        <!--        <template #cell(state)="data">-->
        <!--          <b-link-->
        <!--              @click="changeState(data.item)"-->
        <!--          >-->
        <!--            <b-badge-->
        <!--                pill-->
        <!--                :variant="`light-${resolveStateCode(data.item.state).color}`"-->

        <!--            >-->
        <!--              {{ resolveStateCode(data.item.state).label }}-->
        <!--            </b-badge>-->
        <!--          </b-link>-->
        <!--        </template>-->


      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>

        <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectSingle"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'+modalName"
            ref="singleModal"
        >


          <company-select
              ref="companySelect" :companyType="companyType" v-if="modalName ==='销售主体' "
          >
          </company-select>

          <!-- :userDepartment="userDepartment"  判断负责人所属部门-->
          <user-select
              ref="userSelect" v-else-if="modalName === '采购负责人' "
          >
          </user-select>

          <WareHouseSelect
              ref="myWarehouseSelect" v-else-if="modalName === '仓库'">
          </WareHouseSelect>

          <product-select
              ref="productSelect" :storeId="basicStoreId" :companyId="companyId" :warehouseId="warehouseid" :purchaseTeamUserId="purchaseTeamUserId" :purchaseTeamStoreId="purchaseTeamStoreId" v-else-if="modalName === '商品' "
          >
          </product-select>

        </b-modal>

      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BIcon, BootstrapVueIcons,
  VBTooltip, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, getCode, getCodeLabel, toDate, toTime, getCodeColor,getCodeOptions,toMonth} from '@core/utils/filter'
import stockUseList from './stockUseList'
import stockStore from '../stockStore'
import StockLogList from "@/views/apps/stocklog/StockLogList";
import Ripple from "vue-ripple-directive";
import ProductSelect from '@/views/apps/product/product-salesorder/ProductSelect'
import {useToast} from "vue-toastification/composition";
import WarehouseList from '@/views/apps/warehouse/WarehouseList'
import warehouselocationStore from "@/views/apps/warehouselocation/warehouselocationStore";
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import {getUserData} from "@/auth/utils";
import CompanySelect from "@/views/apps/company/CompanySelect"
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import XyInputButton from "@/views/components/xy/XyInputButton";

export default {
  components: {
    XyInputButton,
    StockLogList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
    BAlert,
    ProductSelect,
    WarehouseList,
    WareHouseSelect,
    CompanySelect,
    UserSelect,
  },
  data() {
    return {
      checked: [],
      salesorderitem: ref({}),
      alldata: [],
      wareHouseName: "",
      wareHouseId: null,
      user: {},
      advanced_search_modal_excel: false,
      modalName: '',
      companyType: '',
      supplierName: '',
      PUserName: '',
      productName:'',
      orderByArray:[{"label":"过期日期","code":"expiry_date"}],
      warehouseArray:[],
    }
  },
  methods: {
    showSingleModal(modalName, data) {
      if (modalName === '渠道方' || modalName === '平台') {
        this.companyType = 'ChannelCustomer'
      } else if (modalName === '销售主体') {
        this.companyType = 'Group'
      } else if (modalName === '店铺') {
        this.companyType = 'Store'
      } else if (modalName === '供应商') {
        this.companyType = 'Supplier'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()

    },
    onSelectSingle() {
      let data = {}
      // this.$refs.itemList.refetchData();
      switch (this.modalName) {
        case '销售主体': {
          data = this.$refs.companySelect.getSelected()
          if (data.length > 0) {
            this.supplierName = data[0].company_name;
            this.company_id = data[0].company_id;
          }
          break
        }
        case '商品': {
          data = this.$refs.productSelect.getSelected()
          if (data.length > 0) {
            this.productId = data[0].id
            this.productName = data[0].name
          }
          break;
        }

          // case '采购负责人': {
          //   data = this.$refs.userSelect.getSelected()
          //   if (data.length > 0) {
          //     this.PUserName = data[0].full_name;
          //     this.PUser_id = data[0].user_id;
          //   }
          //   break;
          // }
          // case '仓库': {
          //   data = this.$refs.myWarehouseSelect.getSelected()
          //   if (data.length > 0) {
          //     this.wareHouseName = data[0].warehouse_name
          //     this.searchKeyWare = data[0].warehouse_id
          //   }
          //   break;
          // }
          /*excelY*/
        default: {
          //statements;
          break
        }
      }
    },
    getChecked: function () {
      const array = []
      this.$forceUpdate()
      for (let i = 0; i < this.alldata.length; i++) {
        if (this.alldata[i].isSelect==1){
          array.push(this.alldata[i])
        }
      }
      return array
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    companyId: {
      type: Number,
      default: 0,
    },
    basicStoreId: {
      type: Number,
      default: 0,
    },
    warehouseid: {
      type: Number,
      default: 0,
    },
    purchaseTeamUserId:{
      type: Number,
      default: 0,
    },
    purchaseTeamStoreId:{
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stock')) store.registerModule('stock', stockStore)
    if (!store.hasModule('warehouselocation')) store.registerModule('warehouselocation', warehouselocationStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stock')) store.unregisterModule('stock')
      if (store.hasModule('warehouselocation')) store.unregisterModule('warehouselocation')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
    })

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return null
      }
    }

    const changeStock = function (item) {
      this.stockid = item.stock_id
    }

    const searchByCondition = function () {
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(this.qty)) {
        toast.error('使用数量必须是正整数!')
        this.qty = 0
      }else {
        this.checked.length=0
        refetchData()
      }

    }

    const resetCondition = function () {
      this.wareHouseName = ''
      this.supplierName = ''

      this.company_id = ''
      this.status = ''
      this.soWarehouseId = ''
      this.productName = ''
      this.productId = ''
      this.orderByOption = ''
      this.qty = 0
      this.isReduceStock = 0
      this.vmi = 0
      this.checked.length = 0
      this.month = ""

      refetchData()
    }

    const changeQty = function (event,params,count) {
      this.alldata[count].ext.useQty=event
      if (parseInt(event)>(params.qty-params.ext.outboundTrueQty)){
        toast.error("超出数量!")
        params.ext.useQty = 0
      }
    }

    const changeSelect = function (params,event,count) {
      this.alldata[count][params]=event
    }

    const setWarehouse = function () {
        // // this.salesorder.store_id = event.store_id
        // // this.salesorder.store_name = event.label
        // // this.salesorder.store_company_id = event.store_company_id
        // const warehouseArray = this.warehouseArray
        // // salesWarehouseArray.length = 0
        // // this.salesorder.sales_warehouse = null
        // // this.salesWarehouse = ""
        // store.dispatch('warehouse/findWareHouseIdsByStoreIds', {storeId: props.basicStoreId})
        //     .then(function (response) {
        //       let data = response.data.data
        //       for (let i = 0; i < data.length; i++) {
        //         warehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
        //       }
        //     })
    }

    const fromChildren = function (params) {
      this.month = params == null ? null : params.month
      this.$forceUpdate()
    }

    const {
      searchList,
      searchALlList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      company_id,productId,status,isReduceStock,orderByOption,qty,soWarehouseId,vmi,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      allCost,
      month,
      click,
      // UI
    } = stockUseList(
        {
          warehouseId: props.warehouseid,
          purchaseTeamUserId:props.purchaseTeamUserId,
          purchaseTeamStoreId:props.purchaseTeamStoreId
        }
    )
    return {
      searchList,
      searchALlList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      company_id,productId,status,isReduceStock,orderByOption,qty,soWarehouseId,vmi,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      allCost,

      // Filter
      avatarText,
      getCodeLabel,
      getCodeColor,
      getCode,
      getCodeOptions,
      toMonth,

      // UI
      toDate,
      toTime,
      isGift,
      changeStock,
      searchByCondition,
      resetCondition,
      changeQty,
      changeSelect,
      setWarehouse,
      month,
      fromChildren,

    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    this.setWarehouse()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
 <div>
  <b-card
          no-body
          class="mb-0"
  >
    <!-- form -->
    <b-form id="statementchangeForm" class="edit-form mt-2">
      <b-row>
        <!--结算单位-->
        <b-col md="3">
          <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="结算单位"
                  label-for="store_name"
                  label-size="sm"
                  class="mb-1 required"
          >
            <b-form-input
                    id="store_name"
                    size="sm"
                    v-model="statementchange.store_name"
                    readonly
            />
          </b-form-group>
        </b-col>
        <!--结算编号  对账单编号-->
        <b-col md="3">
          <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="结算编号"
                  label-for="statement_no"
                  label-size="sm"
                  class="mb-1"

          >
            <b-form-input
                    id="statement_no"
                    size="sm"
                    v-model="statementchange.statement_no"
                    readonly
            />
          </b-form-group>
        </b-col>

        <!--调整编号-->
        <b-col md="3">
          <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="调整编号"
                  label-for="change_no"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-input
                    id="change_no"
                    size="sm"
                    v-model="statementchange.change_no"
                    readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="3"></b-col>
        <!--调整人-->
        <b-col md="3">
          <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="调整人"
                  label-for="creator"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-input
                    id="creator"
                    size="sm"
                    v-model="creatorName"
                    readonly
            />
          </b-form-group>
        </b-col>
        <!--调整金额-->
        <b-col md="3">
          <b-form-group
                  label-cols="4"
                  label-cols-lg="4"
                  label="调整金额"
                  label-for="amount"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-input
                    id="amount"
                    size="sm"
                    v-model="totalAmount"
                    readonly
            />
          </b-form-group>
        </b-col>
        <!--备注-->
        <b-col md="11">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="备注"
                  label-for="remark"
                  label-size="sm"
                  class="mb-1"
          >
            <b-form-input
                    id="remark"
                    size="sm"
                    v-model="statementchange.remark"
            />
          </b-form-group>
        </b-col>
        <!--附件-->
        <b-col md="11">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments"
                  label-size="sm"
                  class="mb-1"
          >
            <!--v-if="statementchange.loaded"-->
            <attachment-upload  :theme="'files'"
                                :attachment_id="'attachments'"
                                :id="statementchange.attachments"
                                :object_type="'statement_change'"
                                :object_id="statementchange.id"
                                @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>

  <b-card style="margin-top: 15px">

    <!-- todo 列表加载原订货列表里的商品明细。  order_id  根据关联的sales_order.id  查询销售单   根据销售单 的关联订货单 id 查询订货单明细-->
   <!-- <b-col>
      <pre-sales-order-item-list ref="preSalesOrderItemList"    :orderId="preSalesOrderId"  v-if="showItem"    v-on:totalAmount = "getTotalAmount">
      </pre-sales-order-item-list>
    </b-col>-->

     <b-col>
     <sales-order-item-list ref="preSalesOrderItemList"    :orderId="preSalesOrderId"  v-if="showItem"    v-on:totalAmount = "getTotalAmount">
     </sales-order-item-list>
   </b-col>

    <b-col
            cols="12"
            class="mt-50"
    >
      <b-button
              variant="primary"
              class="mr-1"
              @click="save(1)"
      >
        保存
      </b-button>

      <b-button
              variant="primary"
              class="mr-1"
              @click="save(2)"
      >
        提交
      </b-button>

      <b-button
              variant="outline-secondary"
              @click="cancel"
      >
        取消
      </b-button>
    </b-col>
  </b-card>
 </div>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import statementchangeStore from './statementchangeStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isNumber} from '@core/utils/filter'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import StatementChangeItemList from "@/views/apps/statementchangeitem/StatementChangeItemList";
import PreSalesOrderItemList from "@/views/apps/presalesorderitem/modal/PreSalesOrderItemList";
import statementchangeitemStore from "@/views/apps/statementchangeitem/statementchangeitemStore";
import SalesOrderItemList from "@/views/apps/salesorderitem/modal/SalesOrderItemList";


export default {
  components: {
    PreSalesOrderItemList,
    StatementChangeItemList,
    SalesOrderItemList,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      statementchange: ref({}),
      statementItemId:0,
      salesOrderId:0,
      creatorName:'',
      preSalesOrderId:0,
      showItem:false,
      totalAmount:0,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('statementchange')) store.registerModule('statementchange', statementchangeStore)
    if (!store.hasModule('statementchangeitem')) store.registerModule('statementchangeitem', statementchangeitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementchange')) store.unregisterModule('statementchange')
      if (store.hasModule('statementchangeitem')) store.unregisterModule('statementchangeitem')
    })

    const edit = function() {
      store.dispatch('statementchange/edit', {id: this.id,statementItemId:this.statementItemId}).then(res => {
        this.statementchange = res.data.data
        this.creatorName = getCodeLabel("user",this.statementchange.creator)  //调整人赋值
        this.showItem = true
        this.preSalesOrderId = this.statementchange.ext.preSalesOrderId
      })
    }



    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function(status) {
      let dataList =  this.$refs.preSalesOrderItemList.tableList ;
      this.statementchange.status = status
      if(dataList.length>0){
         store.dispatch('statementchange/save', this.statementchange).then(res => {
           if(res.data.code===0){
              let changeId = res.data.data.change_id;
              store.dispatch('statementchangeitem/save',{dataList:dataList,changeId:changeId}).then(res=>{
                if(res.data.code===0){
                  toast.success('数据已保存!')
                  this.$router.push({ name: 'apps-statementchange-list'});
                }
              })
           }

         })
      }else{
        toast.error("调整明细列表获取错误！")
        return false
      }
    }

    const onUploaded = function (id, attachment, result) {
      this.statementchange[id] = result
    }

    const getTotalAmount = function (val){
      this.totalAmount = val
      this.statementchange.amount =  val
    }



    return {
      edit,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      getTotalAmount,

    }
  },
  created() {
    this.statementItemId = this.$route.query.statement_item_id || 0;
    this.salesOrderId = this.$route.query.salesOrderId || 0;
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
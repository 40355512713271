import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import stockStore from "@/views/apps/stock/stockStore";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCode} from "@core/utils/filter";

export default function salesorderitemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [

    /*{ key: 'code', label: '69码' },
    { key: 'name', label: '商品名称' },
    { key: 'specification', label: '规格' },
    { key: 'sales_qty', label: '销售数量'},
    { key: 'refuse_qty', label: '拒收数量'},
    { key: 'sales_price', label: '单价' },
    { key: 'price_adjustment', label: '调整单价' },
    { key: 'after_price_adjustment', label: '调整后单价' },
    { key: 'total_adjustment', label: '调整金额' },
    { key: 'memo', label: '备注'},*/

    { key: 'code', label: '69码' }, //
    { key: 'name', label: '商品名称' },  //
    { key: 'specification', label: '规格' }, //
    { key: 'qty', label: '销售数量'}, //
    { key: 'refuse_qty', label: '拒收数量'},  //
    { key: 'product_cost_tax', label: '单价' },   //
    { key: 'cost_price_modify', label: '调整单价' },  //
    { key: 'cost_price_modifyed', label: '调整后单价' },//
    { key: 'total_adjustment', label: '调整金额' },
    { key: 'memo', label: '备注'},


    // { key: 'product_cost', label: '单价   product_cost_tax / (1 + tax_rate)', sortable: true },
    //{ key: 'product_cost_subtotal', label: '单价小计  product_cost * qty', sortable: true },
    //{ key: 'product_cost_tax', label: '含税单价    product_cost + (product_cost * tax_rate )', sortable: true },
    //{ key: 'product_cost_tax_subtotal', label: '含税单价    product_cost + (product_cost * tax_rate )', sortable: true },
    // { key: 'cost', label: '金额', sortable: true },
    //{ key: 'cost_price', label: '成本价  商品入库时的含税单价', sortable: true },
    //{ key: 'qty', label: '销售数量', sortable: true },
    //{ key: 'refund_amount', label: '售后金额', sortable: true },
    //{ key: 'refund_qty', label: '售后数量', sortable: true },
    //{ key: 'pre_item_id', label: '销售前置单明细ID pre_sales_order_item.item_id', sortable: true },
    //{ key: 'cost_price_modify', label: '调整成本单价', sortable: true },
    //{ key: 'cost_price_modifyed', label: '调整后成本单价', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(100)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(true)
  const list = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })


  const searchList = (ctx, callback) => {
    store
      .dispatch('salesorderitem/searchForStatementChange', {
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_id:config.order_id,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const lists = data.ext.list
        listTotals.value = data.ext.totals
        list.value = data.ext.list
        callback(lists)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    list:list,
    refetchData,

  }
}

<template>


  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="tableList"
      >

        <!-- Columns -->




        <!-- Column: State -->
        <template #cell(refuse_qty)="data">
            {{data.item.refuse_qty===undefined?0:data.item.refuse_qty}}
        </template>

        <template #cell(price_adjustment)="data">
          <b-form-input
                  id="price_adjustment"
                  size="sm"
                  v-model="data.item.price_adjustment"
                  @blur="calTotalAmount(data)"
                  type="number"
                  style="width:80px"
          />
        </template>

        <template #cell(memo)="data">
          <b-form-input
                  id="memo"
                  size="sm"
                  v-model="data.item.memo"
                  type="text"
                  style="width:80px"
          />
        </template>


        <template #cell(after_price_adjustment)="data">
          {{addNumber(data.item.price_adjustment,data.item.sales_price)}}
        </template>

        <template #cell(total_adjustment)="data">
         {{ (isNumber(data.item.sales_qty)-isNumber(data.item.refuse_qty))*isNumber(data.item.price_adjustment) }}
        </template>

        <!-- Column: Actions -->


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor , isNumber, addNumber} from '@core/utils/filter'
import presalesorderitemUseList from '@/views/apps/presalesorderitem/modal/presalesorderitemUseList'
import presalesorderitemStore from '@/views/apps/presalesorderitem/presalesorderitemStore'
import router from "@/router";
import {useToast} from "vue-toastification/composition";
import {useRouter} from "@core/utils/utils";
import {useUtils} from "@core/libs/acl";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
  },
  data() {
    return {
      tableList:[],
      totalAmount:0,
    }
  },
  props:{
    orderId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const router = useRouter()
    const toast = useToast()
    // Register module
    if (!store.hasModule('presalesorderitem')) store.registerModule('presalesorderitem', presalesorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('presalesorderitem')) store.unregisterModule('presalesorderitem')
    })

    const calTotalAmount = function (data) {
       let result = Math.abs(isNumber(data.item.price_adjustment))
       let  toastErr = false
       if(result-isNumber(data.item.sales_price)>0){
         data.item.price_adjustment= 0
         toastErr = true
       }
      data.item.after_price_adjustment = addNumber(data.item.price_adjustment,data.item.sales_price)
      data.item.totalAmount =  (isNumber(data.item.sales_qty)-isNumber(data.item.refuse_qty))*isNumber(data.item.price_adjustment)
      // 计算调整总金额
      this.totalAmount = 0
      if(this.tableList.length>0){
        let list =  this.tableList
        list.forEach(o => {
          this.totalAmount += isNumber(o.totalAmount)
        })
      }
      this.$emit("totalAmount",this.totalAmount)
      if(toastErr){
        toast.error("调整单价绝对值必须小于单价！")
        return false
      }

    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = presalesorderitemUseList({
      orderId : props.orderId
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime, 
     toDate,
      calTotalAmount,
      isNumber, addNumber,


    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>

  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="tableList"
      >

        <!-- Columns -->




        <!-- Column: State -->
        <template #cell(refuse_qty)="data">
          {{isNumber(data.item.ext.refuse_qty)}}
        </template>

        <template #cell(cost_price_modify)="data">
          <b-form-input
                  id="cost_price_modify"
                  size="sm"
                  v-model="data.item.cost_price_modify"
                  @blur="calTotalAmount(data)"
                  type="number"
                  style="width:80px"
          />
        </template>

        <template #cell(memo)="data">
          <b-form-input
                  id="memo"
                  size="sm"
                  v-model="data.item.memo"
                  type="text"
                  style="width:80px"
          />
        </template>


        <template #cell(cost_price_modifyed)="data">
          {{addNumber(data.item.cost_price_modify,data.item.product_cost_tax)}}
        </template>

        <template #cell(code)="data">
          {{data.item.ext.productcode}}
        </template>

        <template #cell(specification)="data">
          {{data.item.ext.specification}}
        </template>


        <template #cell(total_adjustment)="data">
          {{ ((isNumber(data.item.qty)-isNumber(data.item.ext.refuse_qty))*isNumber(data.item.cost_price_modify)).toFixed(2) }}
        </template>

        <!-- Column: Actions -->


      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isNumber, addNumber
} from '@core/utils/filter'
import salesorderitemUseList from '@/views/apps/salesorderitem/modal/salesorderitemUseList'
import salesorderitemStore from '@/views/apps/salesorderitem/salesorderitemStore'
import StockList from "@/views/apps/stock/StockList";
import {useToast} from "vue-toastification/composition";
import Ripple from 'vue-ripple-directive'
import StockManage from "@/views/apps/stock/salesorder-stock/StockManage";
import {useRouter} from "@core/utils/utils";

export default {
  components: {
    StockManage,
    StockList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      tableList:[],
      totalAmount:0,
    }
  },
  props:{
    orderId: {
      type: Number,
      default: 0,
    },
   },
  setup(props) {
    const router = useRouter()
    const toast = useToast()


    if (!store.hasModule('salesorderitem')) store.registerModule('salesorderitem', salesorderitemStore)

    onUnmounted(() => {
      if (store.hasModule('salesorderitem')) store.unregisterModule('salesorderitem')
    })

    const calTotalAmount = function (data) {
      let result = Math.abs(isNumber(data.item.cost_price_modify))
      let  toastErr = false
      if(result-isNumber(data.item.product_cost_tax)>0){
        data.item.cost_price_modify= 0
        toastErr = true
      }
      data.item.cost_price_modifyed = addNumber(data.item.cost_price_modify,data.item.product_cost_tax)
      data.item.totalAmount =  (isNumber(data.item.qty)-isNumber(data.item.ext.refuse_qty))*isNumber(data.item.cost_price_modify)
      // 计算调整总金额
      this.totalAmount = 0
      if(this.tableList.length>0){
        let list =  this.tableList
        list.forEach(o => {
          this.totalAmount += isNumber(o.totalAmount)
        })
      }
      this.$emit("totalAmount",this.totalAmount.toFixed(2))
      if(toastErr){
        toast.error("调整单价绝对值必须小于单价！")
        return false
      }

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      list,

      // UI
    } = salesorderitemUseList({
      order_id: props.orderId
    })

    return {
      list,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime, toDate,calTotalAmount,isNumber,addNumber

    }
  },
  created() {
    // this.blurProductCostTax(this.list)
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

button {
  float: left;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
